import React, { createContext, useMemo, useCallback, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MapDimensionsDecode, MapDimensionsEncode, QS_ALIAS } from 'constants/queryStrings'
import { ANALYSIS_BASE_PATH } from 'constants/routeUrls'
import { parseQS, resolveQueryArray, stringifyQS } from 'utils/queryString'
import { DEFAULT_DIMENSION_ID, Dimensions } from 'constants/'

export interface IsetDimension {
  subDimension: string
  selectedDimensionId: string
}

interface IDashboardType {
  dimension: string
  id: string
}

export interface IAnalysisDimensionProps {
  dimensionId: string
  dimension: string
  setDimensionDetails: (options: IsetDimension) => void
}

interface IAnalysisUrlContextProps extends IAnalysisDimensionProps {
  dashboard: IDashboardType

  navigateToDefaultBrowse: (props: Record<string, string>) => void
  navigateToUpdatedBrowse: (
    props: Record<string, string>,
    isAnalysisBasePreferred?: boolean
  ) => void
  queryString: string
  setDashboard: (dimension: string, id: string) => void
  setDashboardUpdateNav: (dimension: string, id: string, isAnalysisBasePreferred?: boolean) => void
}

const defaultDashboard = { dimension: Dimensions.ProductCategory, id: DEFAULT_DIMENSION_ID }

const defaultContextProps = {
  dashboard: defaultDashboard,
  dimension: Dimensions.ProductCategory,
  dimensionId: DEFAULT_DIMENSION_ID,
  navigateToDefaultBrowse: () => {},
  navigateToUpdatedBrowse: () => {},
  queryString: '',
  setDashboard: () => {},
  setDashboardUpdateNav: () => {},
  setDimensionDetails: () => {},
}

export const AnalysisUrlContext = createContext<IAnalysisUrlContextProps>(defaultContextProps)
interface IAnalysisUrlContextProviderProps {
  children: React.ReactNode
}

function encodeDimensions(dimensions: string[]) {
  return dimensions?.map(d => MapDimensionsEncode[d])
}

function parsedDimensions(search) {
  const queryObject = parseQS(search)
  const [dashboardDimension, dashboardItemId] = resolveQueryArray(queryObject[QS_ALIAS.DASHBOARD])
  return {
    dashboardDimension: MapDimensionsDecode[dashboardDimension] || defaultDashboard.dimension,
    dashboardItemId: dashboardItemId || defaultDashboard.id,
    dimension:
      MapDimensionsDecode[queryObject[QS_ALIAS.DIMENSIONS] as string] || Dimensions.ProductCategory,
    dimensionItemId: (queryObject[QS_ALIAS.DIMENSION_IDS] as string) || DEFAULT_DIMENSION_ID,
  }
}

export default function AnalysisUrlContextProvider({ children }: IAnalysisUrlContextProviderProps) {
  const location = useLocation()
  const {
    dashboardDimension: defaultDashboardDimension,
    dashboardItemId: defaultDashboardDimensionItemId,
    dimension: defaultDimension,
    dimensionItemId: defaultDimensionId,
  } = parsedDimensions(location.search)
  const [dimension, setDimension] = useState<string>(defaultDimension)
  const [dimensionId, setDimensionId] = useState<string>(defaultDimensionId)
  const [dashboard, setDashboards] = useState<IDashboardType>({
    dimension: defaultDashboardDimension,
    id: defaultDashboardDimensionItemId,
  })
  // TODO: If possible consider useSearchParams API
  const navigate = useNavigate()

  useEffect(() => {
    const {
      dashboardDimension,
      dashboardItemId,
      dimension: newDimension,
      dimensionItemId,
    } = parsedDimensions(location.search)
    setDashboards({ dimension: dashboardDimension, id: dashboardItemId })
    setDimension(newDimension)
    setDimensionId(dimensionItemId as string)
  }, [location.search])

  const navigateToUpdatedBrowse = useCallback(
    (props: Record<string, string[] | string>, isAnalysisBasePreferred?: boolean) => {
      const queryObject = parseQS(location.search)
      const newQueryObj = { ...queryObject, ...props }
      const basePath = isAnalysisBasePreferred ? ANALYSIS_BASE_PATH : location.pathname
      const navigationOptions = isAnalysisBasePreferred ? undefined : { replace: true }

      navigate(`${basePath}?${stringifyQS(newQueryObj)}`, navigationOptions)
    },
    [location.pathname, location.search, navigate]
  )

  const setDimensionDetails = useCallback(
    ({ subDimension, selectedDimensionId }: IsetDimension) => {
      navigateToUpdatedBrowse({
        [QS_ALIAS.DIMENSION_IDS]: selectedDimensionId,
        [QS_ALIAS.DIMENSIONS]: MapDimensionsEncode[subDimension],
      })
    },
    [navigateToUpdatedBrowse]
  )

  const setDashboard = useCallback(
    (currentDimension: string, id: string) => {
      navigateToUpdatedBrowse({
        [QS_ALIAS.DASHBOARD]: [MapDimensionsEncode[currentDimension], id],
      })
    },
    [navigateToUpdatedBrowse]
  )

  const setDashboardUpdateNav = useCallback(
    (currentDimension: string, id: string, isAnalysisBasePreferred?: boolean) => {
      navigateToUpdatedBrowse(
        {
          [QS_ALIAS.DASHBOARD]: [MapDimensionsEncode[currentDimension], id],
          [QS_ALIAS.DIMENSIONS]: MapDimensionsEncode[currentDimension],
          [QS_ALIAS.DIMENSION_IDS]: id,
        },
        isAnalysisBasePreferred
      )
    },
    [navigateToUpdatedBrowse]
  )

  const navigateToDefaultBrowse = useCallback(
    (queryParams: Record<string, string[] | string>) => {
      setDashboards(defaultDashboard)
      setDimension(Dimensions.ProductCategory)
      setDimensionId(DEFAULT_DIMENSION_ID)
      navigateToUpdatedBrowse({
        [QS_ALIAS.DIMENSIONS]: encodeDimensions([Dimensions.ProductCategory]),
        [QS_ALIAS.DIMENSION_IDS]: DEFAULT_DIMENSION_ID,
        [QS_ALIAS.DASHBOARD]: [
          MapDimensionsEncode[Dimensions.ProductCategory],
          DEFAULT_DIMENSION_ID,
        ],
        ...queryParams,
      })
    },
    [navigateToUpdatedBrowse]
  )

  const providerValue = useMemo(
    () => ({
      dashboard,
      dimension,
      dimensionId,
      navigateToDefaultBrowse,
      navigateToUpdatedBrowse,
      queryString: location.search,
      setDashboard,
      setDashboardUpdateNav,
      setDimensionDetails,
    }),
    [
      dashboard,
      dimension,
      dimensionId,
      location.search,
      navigateToDefaultBrowse,
      navigateToUpdatedBrowse,
      setDashboard,
      setDashboardUpdateNav,
      setDimensionDetails,
    ]
  )
  return <AnalysisUrlContext.Provider value={providerValue}>{children}</AnalysisUrlContext.Provider>
}
