import React, { ReactElement } from 'react'
import { Avatar, Button, Dialog, Icon, TriggerButton } from '@mondra/ui-components'
import classNames from 'classnames'
import { InfoTooltip } from 'components/InfoTooltip'
import { NullableStringType } from 'types'

interface IListItem {
  id: string
  name: NullableStringType
}

interface INameSectionProps {
  name: string
  showAvatarOnTrigger?: boolean
  trigger: boolean
}

function NameSection({ name, showAvatarOnTrigger, trigger }: INameSectionProps) {
  return (
    <div
      className={classNames(
        'flex h-14 items-center justify-between gap-x-2 text-white',
        trigger ? 'w-[215px]' : 'w-[263px] px-6 text-sm'
      )}
    >
      <div className="flex items-center gap-x-2 truncate">
        <div className="truncate" title={name}>
          {trigger ? name || 'Select company' : name}
        </div>
        {trigger && <Icon type="chevronDown" size={20} className="text-current" />}
      </div>
      {showAvatarOnTrigger && <Avatar name={name} size="sm" />}
    </div>
  )
}

interface ListSelectDialogProps<T> {
  description?: string
  items: T[]
  onClose: () => void
  onOpen: () => void
  onSelect: (item: T) => void
  open: boolean
  preListComp?: ReactElement
  selected?: T
  showAvatarOnTrigger?: boolean
  title: string
}

export function ListSelectDialog<T extends IListItem>({
  description,
  items,
  onClose,
  onOpen,
  onSelect,
  open,
  preListComp,
  selected,
  showAvatarOnTrigger,
  title,
}: ListSelectDialogProps<T>) {
  const handleSelect = (item: T) => () => {
    onSelect(item)
  }

  const handleKeyDownSelect = (item: T) => e => {
    if (e.key === 'Enter') {
      onSelect(item)
    }
  }

  return (
    <>
      {items.length > 0 &&
        (items.length === 1 ? (
          <NameSection
            name={selected?.name!}
            showAvatarOnTrigger={showAvatarOnTrigger}
            trigger={false}
          />
        ) : (
          <TriggerButton className="!py-0" onClick={onOpen}>
            <NameSection name={selected?.name!} showAvatarOnTrigger={showAvatarOnTrigger} trigger />
          </TriggerButton>
        ))}

      <Dialog open={open} onClose={onClose} className="!p-0">
        <Dialog.Title className="!mb-1 p-4">
          <div className="flex w-full items-start justify-between gap-x-2">
            <Button className="sr-only" />
            <div className="text-lg font-semibold leading-8 text-primary">
              {title}
              {description && (
                <div className="text-sm font-normal text-coolGray-600">{description}</div>
              )}
            </div>
            <InfoTooltip tooltipKey="SOURCE_COMPANIES" />
          </div>
        </Dialog.Title>
        <Dialog.Description className="px-4">
          <div className="align-center flex flex-1 justify-center">
            <div className="m-auto w-full rounded bg-white">
              {preListComp}
              <ul role="listbox" className="flex h-[360px] flex-col gap-y-1 overflow-y-auto">
                {items?.map((company: T) => {
                  return (
                    <li
                      className="flex cursor-pointer items-center justify-between rounded px-4 py-3 transition-all bg-secondary hover:bg-coolGray-100"
                      key={company.id}
                      onClick={handleSelect(company)}
                      onKeyDown={handleKeyDownSelect(company)}
                      role="option"
                      aria-selected={false}
                      tabIndex={0}
                    >
                      <div className="truncate text-sm font-semibold text-primary">
                        {company.name}
                      </div>
                      <Avatar name={company.name || ''} size="sm" />
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </Dialog.Description>
        <Dialog.Footer className="border-t border-t-gray-300 p-4">
          <Button variant="secondary" size="md" onClick={onClose}>
            Cancel
          </Button>
        </Dialog.Footer>
      </Dialog>
    </>
  )
}
