import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useCompanyFeatures } from 'hooks/useCompanyFeatures'
import { REPORT_FEATURE_FLAGS } from 'constants/featureFlagKeys'
import { EMPTY_STRING } from 'constants/'
import { IOption } from 'components/button-switcher/ButtonSwitcher'
import { DateRange } from 'react-day-picker'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { useReportGenerate } from 'api/useReportGenerate'
import { fileDownloader } from 'utils/fileUtils'
import { useReportDownload } from 'api/useReportDownload'
import { formatApiDate } from 'utils'
import { useReportsHistory } from 'api/useReportsHistory'

interface IReportsProps {
  dateRange?: DateRange
  reportType: string
  reportTypeOptions: IOption[]
  setReportType: (value: string) => void
  selectDateRange: (range?: DateRange) => void
  generateReport: () => void
  downloadReport: (id: string) => void
  isDownloading?: boolean
  isGenerating?: boolean
  hasErrors: boolean
  isSuccess: boolean
}

const defaultContextProps: IReportsProps = {
  dateRange: undefined,
  downloadReport: () => {},
  generateReport: () => {},
  hasErrors: false,
  isDownloading: false,
  isGenerating: false,
  isSuccess: false,
  reportType: '',
  reportTypeOptions: [],
  selectDateRange: () => {},
  setReportType: () => {},
}

export const ReportsContext = createContext<IReportsProps>(defaultContextProps)
interface IReportsProviderProps {
  children: React.ReactNode
}

export default function ReportsContextProvider({ children }: IReportsProviderProps) {
  const { hasFeature } = useCompanyFeatures()
  const sourceCompanyId = useSourceCompanyId()

  const reportTypeOptions = useMemo(
    () => [
      ...(hasFeature(REPORT_FEATURE_FLAGS.FLAG_REPORTS) ? [{ label: 'FLAG', value: 'flag' }] : []),
      ...(hasFeature(REPORT_FEATURE_FLAGS.SCOPE_3_REPORTS)
        ? [{ label: 'Scope 3', value: 'scope3' }]
        : []),
    ],
    [hasFeature]
  )

  const [reportType, setReportType] = useState<string>(reportTypeOptions[0]?.value || EMPTY_STRING)

  const { refreshReportsList } = useReportsHistory({ reportType })

  const [dateRange, setDateRange] = useState<DateRange | undefined>()
  const selectDateRange = useCallback((range?: DateRange) => {
    setDateRange(range)
  }, [])

  const {
    generate,
    isLoading: isGenerating,
    error: generatingError,
  } = useReportGenerate({ reportType })
  const { download, isLoading: isDownloading } = useReportDownload({ reportType })
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const downloadReport = useCallback(
    async (id: string) => {
      const res = await download({
        id,
      })

      fileDownloader(res.downloadUrl, res.fileName, false)
    },
    [download]
  )

  const generateReport = useCallback(async () => {
    setIsSuccess(false)

    try {
      const res = await generate({
        endDate: dateRange?.to ? formatApiDate(dateRange.to) : null,
        sourceCompanyId,
        startDate: dateRange?.from ? formatApiDate(dateRange.from) : null,
      })

      fileDownloader(res.downloadUrl, res.fileName, false)
      setIsSuccess(true)
      refreshReportsList()
    } catch {
      setIsSuccess(false)
    }
  }, [dateRange?.from, dateRange?.to, generate, refreshReportsList, sourceCompanyId])

  // Reset isSuccess message when some of the fields have been changed
  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, dateRange, sourceCompanyId])

  // Reset report type when feature flags has changed
  useEffect(() => {
    setReportType(reportTypeOptions[0]?.value || EMPTY_STRING)
  }, [reportTypeOptions])

  const providerValue = useMemo(
    () => ({
      dateRange,
      downloadReport,
      generateReport,
      hasErrors: !!generatingError,
      isDownloading,
      isGenerating,
      isSuccess,
      reportType,
      reportTypeOptions,
      selectDateRange,
      setReportType,
    }),
    [
      dateRange,
      downloadReport,
      generateReport,
      generatingError,
      isDownloading,
      isGenerating,
      isSuccess,
      reportType,
      reportTypeOptions,
      selectDateRange,
    ]
  )

  return <ReportsContext.Provider value={providerValue}>{children}</ReportsContext.Provider>
}
