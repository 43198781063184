import { IUseToastNotification } from '@mondra/ui-components'
import { ProductStagesEnum, SaTypeEnum } from 'types'

export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000'
export const COMPANY_ID_SEARCH_PARAM = 'c'
export const SOURCE_COMPANY_ID_SEARCH_PARAM = 'sc'

export const SUPPORT_MAIL = 'ops@mondra.com'

export enum Dimensions {
  Ingredient = 'Ingredient',
  IngredientCategory = 'IngredientCategory',
  IngredientSubCategory = 'IngredientSubCategory',
  Product = 'Product',
  ProductCategory = 'ProductCategory',
  ProductSubCategory = 'ProductSubCategory',
  Supplier = 'Supplier',
}
export const DEFAULT_DIMENSION_ID = 'all'
export const DEFAULT_SIGN_DIGIT = 3

export const BIODIVERSITY = 'Biodiversity'
export const DEFORESTATION = 'Deforestation'
export const HUMAN_SLAVERY = 'Human Slavery'
export const WATER_POLLUTION = 'Water Pollution'
export const WATER_USAGE = 'Water Usage'

export const MAIN_PAGE_TITLE = 'Full Assortment'
export const IMPACT_PER_KG_TITLE = 'per kg'
export const IMPACT_PER_KG_AVG = 'per kg (median)'
export const CI_PER_KG_AVG_TITLE = 'CI Score\n(median)'
export const CI_PER_KG_TITLE = 'CI Score'

export const INTENSITY_SCORE = 'Intensity score (median)'

export const PAGE_SIZE = 50
export const MAGIC_PAGE_SIZE = 10000
export const GRID_ROW_HEIGHT = 48

export const DIMENSION_LABELS = {
  Product: 'Product',
  ProductCategory: 'Category',
}

export const PROGRESS_WIDTH_OFFSET = 45

export const PRODUCT_STAGES = [
  {
    border: 'ring-primary-500',
    colorCls: 'bg-brand',
    defaultColorCls: 'bg-primary-400',
    description:
      'Agricultural inputs including fertiliser, feed and water. Per kg yield impacts to farm gate.',
    hover: 'hover:ring-primary-500 hover:ring-1',
    id: ProductStagesEnum.FARMING,
    label: 'Farming',
  },
  {
    border: 'ring-violet-500',
    colorCls: 'bg-violet-500',
    defaultColorCls: 'bg-violet-400',
    description:
      'Impacts from refining, conversion & waste of products through the full value chain.',
    hover: 'hover:ring-violet-500 hover:ring-1',
    id: ProductStagesEnum.PROCESSING,
    label: 'Processing',
  },
  {
    border: 'ring-cyan-500',
    colorCls: 'bg-cyan-500',
    defaultColorCls: 'bg-cyan-400',
    description:
      'Movement of ambient / chilled / frozen product. Impacts of land, sea and air transportation.',
    hover: 'hover:ring-cyan-600 hover:ring-1',
    id: ProductStagesEnum.TRANSPORT,
    label: 'Transport',
  },
  {
    border: 'ring-amber-500',
    colorCls: 'bg-amber-500',
    defaultColorCls: 'bg-amber-400',
    description:
      'Impacts of materials and construction of primary & distribution packaging for full value chain.',
    hover: 'hover: ring-amber-500 hover:ring-1',
    id: ProductStagesEnum.PACKAGING,
    label: 'Packaging',
  },
  {
    border: 'ring-blue-500',
    colorCls: 'bg-blue-500',
    defaultColorCls: 'bg-blue-400',
    description:
      'Ambient / chilled / frozen product interim storage, impacts of temperature control.',
    hover: 'hover:ring-blue-500 hover:ring-1',
    id: ProductStagesEnum.STORAGE,
    label: 'Storage',
  },
  {
    border: 'ring-pink-500',
    colorCls: 'bg-pink-500',
    defaultColorCls: 'bg-pink-400',
    description:
      'Energy & waste associated with vending products to the consumer, primarily temperature control.',
    hover: 'hover:ring-pink-500 hover:ring-1',
    id: ProductStagesEnum.SALE,
    label: 'Retail',
  },
  {
    border: 'ring-gray-200',
    colorCls: 'bg-gray-200',
    defaultColorCls: 'bg-gray-200',
    description: '',
    hover: 'hover:ring-gray-200 hover:ring-1',
    id: ProductStagesEnum.INUSE,
    label: 'In use',
  },
  {
    border: 'ring-gray-200',
    colorCls: 'bg-gray-200',
    defaultColorCls: 'bg-gray-200',
    description: '',
    hover: 'hover:ring-gray-200 hover:ring-1',
    id: ProductStagesEnum.DISPOSAL,
    label: 'Disposal',
  },
]

export const NOT_AVAILABLE = 'N/A'
export const EMPTY_HYPHEN = '-'
export const EMPTY_STRING = ''
export const EMPTY_DATA = {}

export const SA_TYPE_LABELS: Record<SaTypeEnum, string> = {
  [SaTypeEnum.Product]: 'Product',
  [SaTypeEnum.Produce]: 'Produce',
  [SaTypeEnum.ComplexIngredient]: 'Composite',
  [SaTypeEnum.SimpleIngredient]: 'Simple',
  [SaTypeEnum.SpecialIngredient]: 'Special ingredient',
  [SaTypeEnum.ComponentIngredient]: 'Component',
}

export const EMAIL_REGEX = '[a-zA-Z0-9.-_+]+@[a-zA-Z0-9-]{2,}([.]{1}[a-zA-Z]{2,})+'

export const EMPTY_COMPANY = {
  city: null,
  companyLogoUrl: null,
  companySize: null,
  country: null,
  description: null,
  features: [],
  id: '',
  isPublic: true,
  name: null,
  postCode: null,
  registrationNumber: null,
  sharePointPath: null,
  ssoConnection: null,
  state: null,
  street: null,
  type: null,
  website: null,
  zohoCrmAccountId: 0,
}

export const FEATURES = {
  RetailerFilter: 'RetailerFilter',
  SupplierInvitation: 'SupplierInvitation',
}

export const MONDRA_HEADERS = { 'X-MONDRA-APP': 'SAAS' }
export const SAAS_APP = 'SAAS'

export enum HuskStageEnum {
  ETL = 'ETL',
}

export enum HuskTypesEnum {
  Owner = 'Owner',
  Coalition = 'Coalition',
}

export const HuskUsedMessageMap = {
  [HuskTypesEnum.Owner]: 'An average of 3 or more products from your private label assortment.',
  [HuskTypesEnum.Coalition]: 'An average of 3 or more products from 3 or more brand owners.',
}

export const HUSK_ATTRIBUTE_LABELS = {
  BRANDED: 'Branded',
  PRIVATE_LABEL: 'Private Label',
}

// Category contains husks: 80% of products within this category have limited records.
// Impact data may be proxied or not present.

// Category contains husks: 60% of products within this category have no impact data.

// Product husk: This is a limited product record as your data was limited. No impact data is present.

export const HUSK_MESSAGES = {
  CAT_ALL_HUSKS:
    'All products within this category have limited records. All impact data is proxied.',
  CAT_ALL_NO_IMPACTS:
    'All products within this category have limited records. Impact data is not present.',
  CAT_HUSKS:
    '{{PERCENT}} of products within this category have limited records. Impact data may be proxied.',
  CAT_HUSKS_AND_NO_IMPACTS:
    '{{PERCENT}} of products within this category have limited records. Impact data may be proxied or not present.',
  CAT_NO_IMPACTS_HUSKS: '{{PERCENT}} of products within this category have no impact data.',
  CI_SCORE: 'Intensity score cannot be calculated as no impact data is present.',
  DEFAULT:
    'This is a limited product record as the brand has not provided data, impact data is proxied.',
  ETL: 'This is a limited product record as your data was limited, impact data is proxied.',
  NO_IMPACT:
    'This is a limited product record as your data was limited. No impact data is present.',
}

export const HuskAccuracyLabelMap = {
  ATTRIBUTE: 'GPC Attribute (most specific) ',
  BRICK: 'GPC Brick (moderately specific)',
  CLASS: 'GPC Class (least specific)',
}

export const TOAST_POSITION: IUseToastNotification = {
  position: 'top-center',
}

export const ACCEPTED_EF_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/rtf',
  'text/rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/png',
  'image/jpeg',
]

export const ACCEPTED_EF_FILE_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.rtf',
  '.png',
  '.jpg',
  '.jpeg',
]
export const MAX_FILE_SIZE = 30 * 1024 * 1024

export const ECOIMPACTS_UNIT = {
  LITERS: 'L',
  LITERS_EQ: 'L eq.',
  METERSQR_Y: 'm\u00B2*y',
  METERSQR_YEAR: 'm\u00B2*year',
  PER_KG: 'kg',
}

// TODO: This prop should be part of default prop for useMutate
// All the try catch handles should be removed and rely on the error response of trigger
export const SWR_DEAULT_ERROR_CONFIG = {
  throwOnError: false,
}

export const EMPTY_IMPACT_DETAILS = {
  unit: EMPTY_STRING,
  value: NOT_AVAILABLE,
}

export const ARCHIVED_PRODUCT_MESSAGE =
  'This product and its associated data are no longer available on Mondra.'
export const SCENARIO_DASHBOARD_ARCHIVED_MESSAGE =
  'No stage impact information is available as this product is no longer on Mondra.'
export const SCENARIO_DASHBOARD_NO_SV_MESSAGE =
  'No stage impact information can be displayed as the volume data for this product is not available.'
export const AVERAGES_DESC =
  'Due to limited recipe data for this product, we have used average ingredient impacts to calculate the product impact. Ingredient averages are based on similar ingredients modelled by Mondra.'

export const MENU_OPEN_WIDTH = 'w-[320px]'
export const MENU_CLOSE_WIDTH = 'w-0'
export const MENU_BG_COLOR = 'bg-coolGray-900'
export const MOTION_DATA = {
  center: {
    opacity: 1,
    x: 0,
    zIndex: 1,
  },
  enter: (direction: number) => {
    return {
      opacity: 0,
      x: direction > 0 ? 320 : -320,
    }
  },
  exit: (direction: number) => {
    return {
      opacity: 0,
      x: direction < 0 ? 320 : -320,
      zIndex: 0,
    }
  },
}

export const TRANSITION_DATA = {
  opacity: { duration: 0.2 },
  x: { damping: 30, stiffness: 300, type: 'tween' },
}

export const DATE_FORMAT = 'dd/MM/yyyy'
