import { DEFAULT_SERVER_ERROR_MESSAGE } from 'api/constants'
import { HuskStageEnum } from 'constants/'
import { ProductSystemEnum } from 'pages/add-data/add-data-form/types'
import { DataModelTypeEnum, HuskTypeEnum, ImpactsType, MonthlyStatsType } from 'types'

interface IData {
  id: string
  name: string
}

export interface IErrorResponse {
  code: string
  message: string
  detail: string
  traceId: string | null
  status: number
  errors?: Record<string, [] | string>
  isValid?: boolean
}

export class MutateError extends Error {
  response: IErrorResponse

  constructor(r: IErrorResponse, m: string = DEFAULT_SERVER_ERROR_MESSAGE) {
    super(m)
    this.response = r
  }
}

export type TMutateError = InstanceType<typeof MutateError>

export interface IMutateScenarioProduct {
  scenarioId: string
  productId: string
}

export const enum SeachTypesEnum {
  PRODUCTS = 'products',
}

export interface ISearchProductResult {
  id: string
  name: string
  sku: string
  category: string
  categoryId: string
  isFavourite: boolean
}

export interface ISearchResponse<T> {
  data: T[]
  totalCount: number
  pageNumber: number
  pageSize: number
  totalPages: number
}

export interface ICompanySyncResult {
  syncStatus: CompanySyncStatusesEnum
  lastChangedDate: string
  lastSyncDate: string
}

export const enum CompanySyncStatusesEnum {
  NOT_AVAILABLE = 'NotAvailable',
  PENDING = 'Pending',
  DELAYED = 'Delayed',
  COMPLETED = 'Completed',
}

export interface ISettings {
  salesStartDate: string
}

export interface IStats {
  startDate: string
  endDate: string
  perKgImpacts: ImpactsType
  salesVolume: number
  salesVolumeImpacts: ImpactsType
  periodStats: MonthlyStatsType
}

export interface IAuthError {
  error?: string
  error_description?: string
}

export interface IEFFile {
  id: string
  fileName: string
  fileSize: number
  progress?: number
  fileDownloadUrl: string
}

export interface INewEFFile {
  file: File
  progress: number
  error?: IErrorResponse
}

export interface IValidationError {
  propertyName: string
  errorMessage: string
  errorCode: string
}

export interface IEFValidation {
  isValid: boolean
  errors: IValidationError[]
}

export interface ICountry {
  code: string
  name: string
}

export interface IEmissionFactorProductsResult {
  id: string
  name: string
  productSite: string
  simpleIngredients: IData[]
  suppliers: IData[]
}

export interface IEmissionFactorIngredientsResult extends IData {}

export interface INodeCompositionPayload {
  id: string | null
  inputQuantityPercentage: number
  name?: string
  saType?: string
  suppliedProductIds?: string[]
}

export interface IVerifyShareScenario {
  companyId: string | null
  companyName: string | null
  supplierId: string | null
}

export interface IProductInformationResponse {
  id: string
  name: string
  supplierName: string
  sku: string
  netWeight: number
  salesVolume: number
  startDate: string
  endDate: string
  specificationId: string
  specificationVersion: number
  specificationDate: string
  dataModelType: DataModelTypeEnum
  hasAverages: boolean
  isBranded: boolean
  family: string
  class: string
  brick: string
  taxonomyAttributeValue: string | null
  huskType: HuskTypeEnum | null
  huskStage: HuskStageEnum | null
  huskBrickCode: string
  huskFamilyCode: string
  huskClassCode: string
  huskFamily: string
  huskClass: string
  huskBrick: string
  huskTaxonomyAttributeValue: string
  emissionFactors: IEmissionFactorData[] | null
}

export interface IEmissionFactorData {
  companyId: string
  id: string
  code: string
  name: string
  taxonomyCode: string
  taxonomyName: string
  countryCode: string
  productionSystem: ProductSystemEnum
}

export interface IBreadcrumbData {
  dimension: string
  id: string
  name: string
  parentId: null
}

export interface IReportsReponse {
  id: string
  fileName: string
  downloadUrl: string
}

export interface IPaginatedResponse<T> {
  data: T[]
  totalCount: number
  pageNumber: number
  pageSize: number
  totalPages: number
}
