import { useMemo } from 'react'
import { useCompanyId } from 'hooks/useCompanyId'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { REPORTS_HISTORY_PAGE_SIZE } from 'pages/reports/constants'
import { IReportHistoryData } from 'pages/reports/types'
import { useSWRWithToken } from './useSWRWithToken'

interface IReportHistoryRequestProps {
  reportType: string
  pageSize?: number
}

export function useReportsHistory({
  reportType,
  pageSize = REPORTS_HISTORY_PAGE_SIZE,
}: IReportHistoryRequestProps) {
  const companyId = useCompanyId()
  const sourceCompanyId = useSourceCompanyId()

  const url = useMemo(
    () =>
      reportType && companyId
        ? resolveSwrUrl(
            API_URLS.REPORTS.GENERATE,
            {
              companyId,
              reportType,
            },
            { pageSize, ...(sourceCompanyId && { sourceCompanyId }) }
          )
        : null,
    [companyId, pageSize, reportType, sourceCompanyId]
  )

  const { data, error, isLoading, mutate } = useSWRWithToken<IReportHistoryData[]>(url, companyId)

  return {
    data,
    error,
    isLoading,
    refreshReportsList: mutate,
  }
}
