import bytes from 'bytes'
import { IErrorResponse } from 'api/types'
import { ACCEPTED_EF_FILE_TYPES, MAX_FILE_SIZE } from 'constants/'

export function getFileValidations(file: File): IErrorResponse | undefined {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'FILE_TOO_BIG',
      detail: 'Please upload a file with allowed file size limit',
      message: `File is too large and should be less than ${bytes(MAX_FILE_SIZE)}`,
      status: 400,
      traceId: 'UI-traceId-FILE_TOO_BIG',
    }
  }

  if (!ACCEPTED_EF_FILE_TYPES.includes(file.type)) {
    return {
      code: 'FILE_TYPE_NOT_SUPPORTED',
      detail: 'Please upload a file with allowed file formats',
      message: `File uploaded are not supported. Only allowed files formats are ${ACCEPTED_EF_FILE_TYPES.join(
        ', '
      )}`,
      status: 400,
      traceId: 'UI-traceId-FILE_TYPE_NOT_SUPPORTED',
    }
  }

  return undefined
}

export function fileDownloader(fileUrl: string, fileName: string, newTab = true) {
  const link = document.createElement('a')
  link.href = fileUrl
  if (newTab) {
    link.setAttribute('target', '_blank')
  }
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
