import React, { useContext, useEffect } from 'react'
import {
  Field as SidebarButtonGroup,
  Radio as SidebarButton,
  RadioGroup as MenuContainer,
} from '@headlessui/react'
import { Icon } from '@mondra/ui-components'
import classNames from 'classnames'
import { IClassNameProps, ISectionListItem } from 'types'
import { AppSidebarContext } from 'contexts/AppSidebarContextProvider'
import { MENU_OPEN_WIDTH, MENU_CLOSE_WIDTH } from 'constants/'

export interface IAppSidebarProps extends IClassNameProps {
  sections: ISectionListItem[]
}

export function AppSidebar({ className, sections }: IAppSidebarProps) {
  const { isExpanded, expandSidebar, collapseSidebar, selected, setSelected } =
    useContext(AppSidebarContext)

  const handleClick = (s: ISectionListItem) => () => {
    if (isExpanded) {
      if (selected?.id === s.id) {
        collapseSidebar()
      }
    } else {
      expandSidebar()
    }
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      expandSidebar()
    }
  }

  useEffect(() => {
    if (!isExpanded) {
      setSelected(null)
    } else if (!selected) {
      setSelected(sections[0])
    }
  }, [isExpanded, sections, selected, setSelected])

  return (
    <div className="z-10 flex border-t border-coolGray-700 bg-coolGray-900">
      <MenuContainer
        value={selected}
        onChange={setSelected}
        className="z-px flex w-14 flex-shrink-0 flex-col items-center gap-4 border-r border-coolGray-700 bg-coolGray-900 pt-2"
      >
        {sections.map(s => (
          <SidebarButtonGroup key={s.id} disabled={s.disabled}>
            <SidebarButton
              title={s.title}
              as="div"
              onClick={handleClick(s)}
              value={s}
              onKeyDown={handleEnterKeyDown}
              className="group flex h-10 w-10 select-none items-center justify-center rounded-lg border border-coolGray-600 bg-coolGray-700 transition-all hover:cursor-pointer hover:bg-graySecondary-700 focus:border-primary-600 focus:!outline-none data-[disabled]:cursor-not-allowed data-[checked]:border-coolGray-700 data-[disabled]:border-coolGray-700 data-[checked]:bg-primary-600 data-[disabled]:bg-coolGray-800 data-[checked]:hover:bg-primary-500"
            >
              {s.buttonIcon && (
                <Icon
                  type={s.buttonIcon}
                  className="text-white group-data-[disabled]:text-coolGray-700"
                  size={20}
                />
              )}
            </SidebarButton>
          </SidebarButtonGroup>
        ))}
      </MenuContainer>
      <div
        className={classNames(
          'max-h-screen-c min-h-screen-c drop-shadow-lg transition-all',
          className,
          isExpanded ? MENU_OPEN_WIDTH : MENU_CLOSE_WIDTH
        )}
      >
        <div className="flex h-full w-full overflow-hidden">{selected?.component}</div>
      </div>
    </div>
  )
}
