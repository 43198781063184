import {
  COMPANY_ID_SEARCH_PARAM,
  EMPTY_HYPHEN,
  IMPACT_PER_KG_AVG,
  IMPACT_PER_KG_TITLE,
} from 'constants/'
import { NullableNumberType } from 'types'
import { isProduct } from './pageUtils'

export function getCompanyId(): string | undefined {
  if (process.env.REACT_APP_ENABLE_MOCK) {
    return 'c71e7139-d51e-422c-0728-08da238b859c'
  }
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get(COMPANY_ID_SEARCH_PARAM) || ''
}

export function isNullify(value?: string | number | null | boolean) {
  return value === '' || value === null || value === undefined
}

export function padTensZero(value: string | number): string {
  return Number(value) <= 9 ? `0${value}` : value.toString()
}

export function percentage(percent: number = 0, total: number = 0) {
  return total === 0 ? 0 : Math.round((percent / total) * 100)
}

export function percentageActual(percent: number = 0, total: number = 0) {
  return total === 0 ? 0 : (percent / total) * 100
}

export function perKgTitle(dimension: string) {
  return isProduct(dimension) ? IMPACT_PER_KG_TITLE : IMPACT_PER_KG_AVG
}

export function getInviteLink(token?: string) {
  return `${process.env.REACT_APP_SUPPLIER_ONBOARDING_URL || ''}/?inviteToken=${token}`
}

export const formatApiDate = (d: Date) => {
  const mm = (d.getMonth() + 1).toString().padStart(2, '0')
  const dd = d.getDate().toString().padStart(2, '0')
  const yyyy = d.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

export function getDisplayString(value: any, isBoolean: boolean = false, _default = EMPTY_HYPHEN) {
  if (isBoolean) {
    return value ? 'Yes' : 'No'
  }
  if (isNullify(value)) {
    return _default
  }
  return value
}

export function getDisplayPercentageString(
  value?: NullableNumberType,
  options: { default: string } = { default: EMPTY_HYPHEN }
) {
  if (isNullify(value)) {
    return options.default
  }
  return `${value}%`
}

export function isNumeric(str: string) {
  if (typeof str !== 'string') return false
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str))
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
