import { useEffect, useMemo, useState } from 'react'
import last from 'lodash/fp/last'
import { API_URLS } from 'constants/apiUrls'
import { useCompanyId } from 'hooks/useCompanyId'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { isNullify } from 'utils'
import { useSourceCompanyId } from 'hooks/useSourceCompanyId'
import { IPaginatedResponse } from 'api/types'
import { useSWRInifiniteWithToken } from 'api/useSWRInfiniteWithToken'
import { IApiParams, IFavourite } from 'types'
import { DIMENSION_LABELS, Dimensions } from 'constants/'

export function useFavourites(pageSize = 20) {
  const companyId = useCompanyId()
  const sourceCompanyId = useSourceCompanyId()

  const [hasMore, setHasMore] = useState(false)

  const urlPath = useMemo(
    () =>
      isNullify(companyId)
        ? null
        : resolveSwrUrl(
            API_URLS.Analysis.FAVOURITES,
            {
              companyId,
            },
            [...(sourceCompanyId ? [['sourceCompanyId', sourceCompanyId]] : [])]
          ),
    [companyId, sourceCompanyId]
  )

  const { data, error, size, setSize, isLoading, isValidating, mutate } = useSWRInifiniteWithToken<
    IApiParams,
    IPaginatedResponse<IFavourite>
  >({
    companyId,
    config: { revalidateFirstPage: false },
    params: {
      pageSize,
    },
    urlPath,
  })

  useEffect(() => {
    if (data && last(data)) {
      const { pageNumber, totalPages } = last(data) as IPaginatedResponse<IFavourite>

      setHasMore(pageNumber < totalPages)
    }
  }, [size, data])

  const loadMore = () => {
    if (hasMore) {
      setSize(size + 1)
    }
  }

  const items = useMemo(() => {
    let result: IFavourite[] = []

    if (data && last(data)) {
      result = data.map(d => d.data).flat()
    }
    return result.map(r => ({
      ...r,
      type:
        r.type === DIMENSION_LABELS.ProductCategory
          ? Dimensions.ProductCategory
          : Dimensions.Product,
    }))
  }, [data])

  return {
    error,
    hasMore,
    isLoading,
    isLoadingMore: isLoading || isValidating,
    items,
    loadMore,
    refreshFavourites: mutate,
  }
}
