import { useToastNotification } from '@mondra/ui-components'
import queryString from 'query-string'
import useSWRInfinite, { SWRInfiniteConfiguration, SWRInfiniteKeyLoader } from 'swr/infinite'
import isEmpty from 'lodash/fp/isEmpty'
import { useToken } from 'hooks/useToken'
import { StringType } from 'types'
import { TOAST_POSITION } from 'constants/'
import { fetchWithToken } from './fetchWithToken'

interface IUseSWRInifiniteWithTokenProps<TParams> {
  companyId?: string
  config?: SWRInfiniteConfiguration
  params: TParams
  urlPath?: StringType
}

export function useSWRInifiniteWithToken<TParams = any, TData = any>({
  companyId,
  config = {},
  params,
  urlPath,
}: IUseSWRInifiniteWithTokenProps<TParams>) {
  const { acquireToken } = useToken()
  const { showError } = useToastNotification(TOAST_POSITION)

  const getKey: SWRInfiniteKeyLoader = index => {
    if (isEmpty(urlPath)) return null
    const paramString = queryString.stringify(
      {
        pageNumber: index + 1,
        ...params,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    )
    return `${urlPath}${urlPath.includes('?') ? '&' : '?'}${paramString}`
  }

  return useSWRInfinite<TData>(
    getKey,
    apiUrl => fetchWithToken({ acquireToken, apiUrl, companyId, showError }),
    config
  )
}
