import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { useMutate } from './useMutate'
import { IReportsReponse } from './types'
import { REPORT_DOWNLOAD_ERROR } from './constants'

interface IReportDownloadProps {
  reportType: string
}

export function useReportDownload({ reportType }: IReportDownloadProps) {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      reportType && companyId
        ? resolveSwrUrl(API_URLS.REPORTS.DOWNLOAD, {
            companyId,
            reportType,
          })
        : null,
    [companyId, reportType]
  )

  const {
    post: download,
    isMutating,
    error,
  } = useMutate<IReportsReponse>(url, companyId, undefined, REPORT_DOWNLOAD_ERROR)

  return {
    download,
    error,
    isLoading: isMutating,
  }
}
