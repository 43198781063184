import React, { Component, ReactNode } from 'react'
import { Icon, Link } from '@mondra/ui-components'
import { GET_SUPPORT } from 'constants/helpContent'
import { ROUTE_URLS } from 'constants/routeUrls'

interface ErrorBoundaryState {
  error?: Error
  hasError: boolean
}
interface ErrorBoundaryProps {
  children: ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
      hasError: true,
    }
  }

  render() {
    const { error, hasError } = this.state
    const { children } = this.props

    if (hasError && error) {
      return (
        <div className="flex min-h-screen flex-grow items-center justify-center">
          <div className="mx-auto flex max-w-4xl flex-grow flex-col items-center justify-center space-y-4 rounded bg-white drop-shadow-2xl">
            <div className="flex w-full items-center space-x-5 border-b border-b-coolGray-300 p-4">
              <div className="flex items-center justify-center rounded-lg bg-red-100 p-2">
                <Icon type="errorOutline" size={32} className="text-red-600" />
              </div>
              <h5 className="flex-shrink-0 text-lg font-semibold leading-7 text-primary ">
                Something went wrong. Please contact us:
              </h5>
            </div>
            <div className="max-h-[350px] w-full space-y-2 overflow-auto p-8">
              <div className="text-sm font-bold leading-5">{error.message}</div>
              <div className="text-sm leading-5">{error.stack}</div>
            </div>
            <div className="flex w-full items-center justify-end space-x-4 border-t border-b-coolGray-300 p-4">
              <Link
                as="a"
                appearance="button"
                variant="secondary"
                to={`${ROUTE_URLS.HOME}${window.location.search}`}
                size="md"
              >
                Go to home
              </Link>
              <Link
                as="a"
                className="inline-block"
                to={GET_SUPPORT}
                target="_blank"
                appearance="button"
                variant="primary"
                size="md"
              >
                Get Support
              </Link>
            </div>
          </div>
        </div>
      )
    }

    return children
  }
}
