import { useMemo } from 'react'
import { API_URLS } from 'constants/apiUrls'
import { resolveSwrUrl } from 'utils/resolveUrl'
import { useCompanyId } from 'hooks/useCompanyId'
import { useMutate } from './useMutate'
import { IReportsReponse } from './types'

interface IReportGenerateProps {
  reportType: string
}

export function useReportGenerate({ reportType }: IReportGenerateProps) {
  const companyId = useCompanyId()
  const url = useMemo(
    () =>
      reportType && companyId
        ? resolveSwrUrl(API_URLS.REPORTS.GENERATE, {
            companyId,
            reportType,
          })
        : null,
    [companyId, reportType]
  )

  const { post: generate, isMutating, error } = useMutate<IReportsReponse>(url, companyId)

  return {
    error,
    generate,
    isLoading: isMutating,
  }
}
